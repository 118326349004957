/* eslint-disable no-param-reassign */
import axios from "axios";
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL;

export default {
   namespaced: true,
   state: {
      items: [],
      data: [],
      countAll: 0,
      current: 0,
      success: 0,
      failed: 0,
      runningState: false,
      cancelToken: "",
   },
   getters: {},
   actions: {
      async getData({ commit, rootState }) {
         rootState.isLoading = true;
         await axios.get(`${baseURL}oss/scrap`).then(
            (response) => {
               commit("SET_DATA", response.data.items);
               commit("SET_COUNT", response.data.items);
               rootState.isLoading = false;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      async getDataError({ commit, rootState }) {
         rootState.isLoading = true;
         await axios.get(`${baseURL}oss/scrap/error`).then(
            (response) => {
               commit("SET_DATA", response.data.items);
               commit("SET_COUNT", response.data.items);
               rootState.isLoading = false;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getDataTable({ commit, rootState }, param) {
         rootState.isLoading = true;
         axios.post(`${baseURL}oss/scrap/table`, param).then(
            (response) => {
               commit("SET_DATA_TABLE", response.data.rows);
               commit("SET_COUNT_TABLE", response.data.totalRecords);
               rootState.isLoading = false;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      runScrap({ commit, rootState, state }, nib) {
         rootState.isLoading = true;
         const param = { nib };
         return axios
            .post(`${baseURL}oss/scrap`, param, {
               cancelToken: state.cancelToken,
            })
            .then(
               () => {
                  commit("SET_SUCCESS");
                  commit("NEXT");
                  rootState.isLoading = false;
                  return Promise.resolve();
               },
               () => {
                  commit("SET_FAILED");
                  commit("NEXT");
                  rootState.isLoading = false;
                  return Promise.resolve();
               }
            );
      },
      clearError({ rootState }, nib) {
         rootState.isLoading = true;
         return axios.delete(`${baseURL}oss/scrap/error/${nib}`).then(
            () => {
               rootState.isLoading = false;
               return Promise.resolve();
            },
            () => {
               rootState.isLoading = false;
               return Promise.resolve();
            }
         );
      },
      nibDetele({ rootState }, nib) {
         rootState.isLoading = true;
         return axios.delete(`${baseURL}oss/scrap/nib/${nib}`).then(
            () => {
               rootState.isLoading = false;
               return Promise.resolve();
            },
            () => {
               rootState.isLoading = false;
               return Promise.resolve();
            }
         );
      },
      storeNib({ rootState }, nib) {
         rootState.isLoading = true;
         return axios.post(`${baseURL}oss/scrap/nib`, { nib }).then(
            () => {
               rootState.isLoading = false;
               return Promise.resolve();
            },
            () => {
               rootState.isLoading = false;
               return Promise.reject();
            }
         );
      },
      runLogin({ rootState }) {
         rootState.isLoading = true;
         return axios.post(`${baseURL}oss/scrap/login`).then(
            () => {
               rootState.isLoading = false;
               return Promise.resolve();
            },
            () => {
               rootState.isLoading = false;
               return Promise.reject();
            }
         );
      },
      importData({ rootState }, file) {
         rootState.isLoading = true;

         const formData = new FormData();
         formData.append("file", file, file.name);

         return axios
            .post(`${baseURL}oss/scrap/import`, formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then(
               () => {
                  rootState.isLoading = false;
                  return Promise.resolve();
               },
               () => {
                  rootState.isLoading = false;
                  return Promise.reject();
               }
            );
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.data = data;
      },
      SET_DATA_TABLE(state, data) {
         state.items = data;
      },
      SET_COUNT(state, data) {
         state.countAll = data.length;
      },
      SET_COUNT_TABLE(state, data) {
         state.countAll = data;
      },
      SET_CURRENT(state, data) {
         state.current = data;
      },
      SET_SUCCESS(state) {
         state.success += 1;
      },
      SET_FAILED(state) {
         state.failed += 1;
      },
      SET_STATE(state, data) {
         state.runningState = data;
      },
      SET_CANCEL_TOKEN(state, data) {
         state.cancelToken = data;
      },
      NEXT(state) {
         state.current += 1;
      },
   },
};
