<script>
import DashboardOss from '@/views/dashboard/Oss.vue'
import DashboardSicantik from '@/views/dashboard/Sicantik.vue'
import DashboardSimbg from '@/views/dashboard/Simbg.vue'
import DashboardLkpm from '@/views/dashboard/Lkpm.vue'
import DashboardPengaduan from '@/views/dashboard/Pengaduan.vue'
import DashboardSkmMpp from '@/views/dashboard/SkmMpp.vue'
import DashboardSkmDpmptsp from '@/views/dashboard/SkmDpmptsp.vue'
import DashboardMppDigital from '@/views/dashboard/MppDigital.vue'

export default {
   components: {
      DashboardOss,
      DashboardSicantik,
      DashboardSimbg,
      DashboardLkpm,
      DashboardPengaduan,
      DashboardSkmMpp,
      DashboardSkmDpmptsp,
      DashboardMppDigital
   },
}
</script>

<template>
   <div>
      <DashboardOss />
      <DashboardSimbg />
      <DashboardSicantik />
      <DashboardSkmDpmptsp />
      <DashboardSkmMpp />
      <DashboardMppDigital />
      <DashboardLkpm />
      <DashboardPengaduan />

   </div>
</template>

<style scoped></style>
