<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { id } from 'vuejs-datepicker/dist/locale'
import PaginationCustom from '@/components/table/Pagination.vue'

export default {
   name: 'SKMData',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   data() {
      return {
         item: {},
         file: null,
         filterDateLang: id,
         filter: {
            tanggal_awal: new Date(),
            tanggal_akhir: new Date(),
            instansi: null,
         },
         options: {
            instansi: [
               { value: null, text: '-' },
               { value: 'POLRES', text: 'POLRES' },
               { value: 'Kejaksaan', text: 'Kejaksaan' },
               { value: 'BNN', text: 'BNN' },
               { value: 'Kementrian Agama', text: 'Kementrian Agama' },
               { value: 'KPP Pratama', text: 'KPP Pratama' },
               { value: 'ATR / BPN', text: 'ATR / BPN' },
               { value: 'SAMSAT', text: 'SAMSAT' },
               { value: 'BRI', text: 'BRI' },
               { value: 'BANK KALBAR', text: 'BANK KALBAR' },
               { value: 'PT PLN', text: 'PT PLN' },
               { value: 'PT POS', text: 'PT POS' },
               { value: 'BPJS Ketenagakerjaan', text: 'BPJS Ketenagakerjaan' },
               { value: 'BPJS Kesehatan', text: 'BPJS Kesehatan' },
               { value: 'PDAM Tirta Senentang', text: 'PDAM Tirta Senentang' },
               { value: 'DPMPTSP', text: 'DPMPTSP' },
               { value: 'DISDUKCAPIL', text: 'DISDUKCAPIL' },
               { value: 'BAPPENDA', text: 'BAPPENDA' },
               { value: 'DPK', text: 'DPK' },
            ],
         },
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Tanggal',
               field: 'tanggal',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Instansi',
               field: 'instansi',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Usia',
               field: 'usia',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Jenis Kelamin',
               field: 'jk',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Pendidikan',
               field: 'pendidikan',
               sortable: false,
               thClass: 'text-center align-middle',
            },

            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
      }
   },
   computed: {
      ...mapState({
         myData: state => state.SkmHasilMpp.items,
         myNilai: state => state.SkmHasilMpp.nilai,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      tanggalPenetapan() {
         return tanggal => {
            let value = ''

            if (tanggal !== '0000-00-00') {
               value = this.$moment(tanggal).format('DD MMMM YYYY - HH:mm:ss')
            } else {
               value = '-'
            }

            return value
         }
      },
      tanggalConvert() {
         return tanggal => {
            let value = ''

            if (tanggal !== '0000-00-00') {
               value = this.$moment(tanggal).format('DD MMMM YYYY')
            } else {
               value = '-'
            }

            return value
         }
      },
      jawaban() {
         return val => {
            let result
            switch (val) {
               case 1:
                  result = 'A'
                  break
               case 2:
                  result = 'B'
                  break
               case 3:
                  result = 'C'
                  break
               case 4:
                  result = 'D'
                  break
               default:
                  result = '-'
                  break
            }

            return result
         }
      },

   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'SkmHasilMpp/getData',
         importData: 'SkmHasilMpp/importData',
      }),
      onDetail(_id) {
         this.item = this.myData.find(item => item.id === _id)
         console.log(this.item)

         this.$bvModal.show('modal-detail')
      },
      onImport() {
         this.$bvModal.show('modal-import')
      },
      onSave() {
         if (this.file) {
            this.importData(this.file).then(() => {
               this.file = null
               this.loadItems()
               this.$bvModal.hide('modal-import')
            })
         } else {
            this.$bvToast.toast('File Excel belum dipilih', {
               title: 'Error',
               variant: 'danger',
               solid: true,
            })
         }
      },

      // VUE GOOD TABLE
      loadItems() {
         this.getData(this.filter)
      },
      onFilter() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      myFilter() {
         // const d = new Date(this.filterDateValue)
         // const filterDateValue = this.$moment(d).format('YYYY-MM-DD')

         // this.form.tanggal = this.filterDateValue
         console.log('tanggal', this.filter.tanggal_awal)
         console.log('tanggal', this.filter.tanggal_akhir)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data SKM MPP">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input v-model="searchTerm" class="d-inline-block mb-1" placeholder="Search..."
                        autocomplete="off" type="text" aria-label="Search" />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button v-b-tooltip.hover.top="'Refresh Data'" variant="outline-secondary" size="sm"
                        :disabled="isLoading" @click="loadItems()">
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-button v-b-tooltip.hover.top="'Filter'" v-b-toggle.collapse-filter variant="outline-secondary"
                        size="sm">
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="onImport()">
                        <feather-icon icon="UploadIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onImport()">
                           Import
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="isLoading" @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
            <!-- FILTER -->
            <b-col>
               <b-collapse id="collapse-filter" class="mt-2">
                  <b-card class="border">
                     <b-row>
                        <b-col md="4">
                           <b-form-group label="Tanggal Awal" label-for="tahun">
                              <datepicker v-model="filter.tanggal_awal" input-class="datepicker-here form-control "
                                 minimum-view="date" maximum-view="year" format="dd MMMM yyyy"
                                 :language="filterDateLang" @input="onFilter" />
                           </b-form-group>
                        </b-col>
                        <b-col md="4">
                           <b-form-group label="Tanggal Akhir" label-for="tahun">
                              <datepicker v-model="filter.tanggal_akhir" input-class="datepicker-here form-control "
                                 minimum-view="date" maximum-view="year" format="dd MMMM yyyy"
                                 :language="filterDateLang" @input="onFilter" />
                           </b-form-group>
                        </b-col>
                        <b-col md="4">
                           <b-form-group label="Instansi" label-for="Instansi">
                              <b-form-select v-model="filter.instansi" :options="options.instansi" @input="onFilter" />
                           </b-form-group>
                        </b-col>
                        <b-col md="12">
                           <b-list-group>
                              <b-list-group-item>
                                 <b>Tanggal</b>
                                 : {{ tanggalConvert(filter.tanggal_awal) }} s/d {{ tanggalConvert(filter.tanggal_akhir)
                                 }}
                              </b-list-group-item>
                              <b-list-group-item>
                                 <b>Instansi</b>
                                 : {{ filter.instansi }}
                              </b-list-group-item>
                              <b-list-group-item>
                                 <b>Responden</b>
                                 : {{ myNilai.responden }}
                              </b-list-group-item>
                              <b-list-group-item>
                                 <b>Indeks</b>
                                 : {{ myNilai.indeks }}
                              </b-list-group-item>
                              <b-list-group-item>
                                 <b>Grade</b>
                                 : {{ myNilai.grade }}
                              </b-list-group-item>
                           </b-list-group>
                           <br>
                           <b>Nilai per Unsur :</b>
                           <table class="table">
                              <thead>
                                 <tr>
                                    <th>U1</th>
                                    <th>U2</th>
                                    <th>U3</th>
                                    <th>U4</th>
                                    <th>U5</th>
                                    <th>U6</th>
                                    <th>U7</th>
                                    <th>U8</th>
                                    <th>U9</th>
                                    <th>U10</th>
                                    <th>U11</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>{{ myNilai.unsur[0] }}</td>
                                    <td>{{ myNilai.unsur[1] }}</td>
                                    <td>{{ myNilai.unsur[2] }}</td>
                                    <td>{{ myNilai.unsur[3] }}</td>
                                    <td>{{ myNilai.unsur[4] }}</td>
                                    <td>{{ myNilai.unsur[5] }}</td>
                                    <td>{{ myNilai.unsur[6] }}</td>
                                    <td>{{ myNilai.unsur[7] }}</td>
                                    <td>{{ myNilai.unsur[8] }}</td>
                                    <td>{{ myNilai.unsur[9] }}</td>
                                    <td>{{ myNilai.unsur[10] }}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </b-col>
                     </b-row>
                  </b-card>
               </b-collapse>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table ref="goodTable" :columns="columns" :rows="myData" :line-numbers="true" :pagination-options="{
            enabled: true,
            mode: 'pages',
         }" :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }">
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'umur'">{{
                  props.row.umur
               }}</span>
               <span v-else-if="props.column.field == 'instansi'">{{
                  props.row.instansi
               }}</span>
               <span v-else-if="props.column.field == 'usia'">{{
                  props.row.usia
               }}</span>
               <span v-else-if="props.column.field == 'jk'">{{
                  props.row.jk
               }}</span>
               <span v-else-if="props.column.field == 'pendidikan'">{{
                  props.row.pendidikan
               }}</span>

               <span v-else-if="props.column.field == 'tanggal'">{{
                  tanggalPenetapan(props.row.tanggal)
               }}</span>

               <span v-if="props.column.field == 'action'">
                  <b-button size="sm" block variant="outline-dark" class="nowrap" @click="onDetail(props.row.id)">
                     <feather-icon icon="EyeIcon" class="mr-50" />
                     <small>Detail</small>
                  </b-button>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom :total="props.total" :page-length="pageLength" :page-options="pageOptions"
                  :page-changed="props.pageChanged" :per-page-changed="props.perPageChanged" />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal id="modal-detail" scrollable size="lg" centered hide-footer title="Detail">
         <b-list-group>
            <b-list-group-item>
               <b>Tanggal</b>
               : {{ tanggalPenetapan(item.tanggal) }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Umur</b>
               : {{ item.umur }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Status Kawin</b>
               : {{ item.status_kawin }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Pekerjaan</b>
               : {{ item.pekerjaan }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Pendidikan</b>
               : {{ item.pendidikan }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Jenis Izin</b>
               : {{ item.jenis }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Saran</b>
               : {{ item.saran }}
            </b-list-group-item>
         </b-list-group>
         <br>
         <b>Jawaban :</b>
         <table class="table">
            <thead>
               <tr>
                  <th>U1</th>
                  <th>U2</th>
                  <th>U3</th>
                  <th>U4</th>
                  <th>U5</th>
                  <th>U6</th>
                  <th>U7</th>
                  <th>U8</th>
                  <th>U9</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>{{ jawaban(item.p1) }}</td>
                  <td>{{ jawaban(item.p2) }}</td>
                  <td>{{ jawaban(item.p3) }}</td>
                  <td>{{ jawaban(item.p4) }}</td>
                  <td>{{ jawaban(item.p5) }}</td>
                  <td>{{ jawaban(item.p6) }}</td>
                  <td>{{ jawaban(item.p7) }}</td>
                  <td>{{ jawaban(item.p8) }}</td>
                  <td>{{ jawaban(item.p9) }}</td>
               </tr>
            </tbody>
         </table>
      </b-modal>

      <b-modal id="modal-import" scrollable size="lg" centered hide-footer title="Form Import" no-close-on-backdrop>
         <b-form-group label="File Excel">
            <b-form-file v-model="file" placeholder="Choose a file or drop it here..."
               drop-placeholder="Drop file here..." />
         </b-form-group>
         <hr>
         <b-button v-b-tooltip.hover.top="'Simpan'" variant="primary" type="button" class="float-right"
            :disabled="isLoading" @click.prevent="onSave">
            <b-spinner v-if="isLoading" small />Import
         </b-button>
      </b-modal>
   </div>
</template>

<style scoped>
.nowrap {
   white-space: nowrap;
}
</style>

<style>
.hidden_header {
   display: none;
}
</style>
