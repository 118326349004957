<script>
import { mapState, mapActions } from 'vuex'
import StatisticCard from '@/components/cards/StatisticCard.vue'

export default {
   components: {
      StatisticCard,
   },
   computed: {
      ...mapState({
         myData: state => state.Dashboard.mppd,
      }),
      getThisYear() {
         return this.$moment().year()
      },
      getThisMonth() {
         return this.$moment().format('MMMM')
      },
   },
   mounted() {
      this.getSkmMpp()
   },
   methods: {
      ...mapActions({
         getSkmMpp: 'Dashboard/getSkmMpp',
      }),
   },
}
</script>

<template>
   <b-row>
      <b-col md="12" cols="12">
         <b-card class="oss-color">
            <b-row>
               <b-col md="3">
                  <b-card no-body class="mb-50">
                     <div class="rounded-top text-center oss-logo-color">
                        <b-img :src="require('@/assets/images/logo/mppd.png')" alt="oss" height="100"
                           class="img-fluid" />
                     </div>
                     <b-card-body>
                        <b-card-title class="mb-25">
                           MPP Digital
                        </b-card-title>
                        <b-card-text class="mb-0">
                           <small>MPP Digital merupakan pelayanan publik berbasis elektronik yang terintegrasi.</small>
                        </b-card-text>
                     </b-card-body>
                  </b-card>
               </b-col>
               <b-col md="9">
                  <b-row>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.terbit}`" color="dark"
                           statistic-title="Diterbitkan" />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.proses}`" color="dark"
                           statistic-title="Dalam Proses" />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.batal}`" color="dark"
                           statistic-title="Dibatalkan" />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.tolak}`" color="dark"
                           statistic-title="Ditolak" />
                     </b-col>
                  </b-row>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>

<style scoped>
.oss-color {
   background-color: #e44545;
   background-image: linear-gradient(135deg, #ec8b8b 0%, #b32b2d 100%);

}

.oss-logo-color {
   background-color: #ffdee9;
   background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}
</style>
