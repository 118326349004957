<script>
import { mapState, mapActions } from 'vuex'
import StatisticCard from '@/components/cards/StatisticCard.vue'

export default {
   components: {
      StatisticCard,
   },
   computed: {
      ...mapState({
         myData: state => state.Dashboard.skmDpmptsp,
      }),
      getThisYear() {
         return this.$moment().year()
      },
      getThisMonth() {
         return this.$moment().format('MMMM')
      },
   },
   mounted() {
      this.getSkmDpmptsp()
   },
   methods: {
      ...mapActions({
         getSkmDpmptsp: 'Dashboard/getSkmDpmptsp',
      }),
   },
}
</script>

<template>
   <b-row>
      <b-col md="12" cols="12">
         <b-card class="oss-color">
            <b-row>
               <b-col md="3">
                  <b-card no-body class="mb-50">
                     <div class="rounded-top text-center oss-logo-color">
                        <b-img :src="require('@/assets/images/logo/survey-dpmptsp.png')" alt="oss" height="100"
                           class="img-fluid" />
                     </div>
                     <b-card-body>
                        <b-card-title class="mb-25">
                           SKM DPMPTSP
                        </b-card-title>
                        <b-card-text class="mb-0">
                           <small>Survei Kepuasan Masyarakat DPMPTSP</small>
                        </b-card-text>
                     </b-card-body>
                  </b-card>
               </b-col>
               <b-col md="9">
                  <b-row>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.tw1.indeks} (${myData.tw1.grade})`"
                           color="dark"
                           :statistic-title="`Triwulan I ${getThisYear} (Responden : ${myData.tw1.responden})`" />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.tw2.indeks} (${myData.tw2.grade})`"
                           color="dark"
                           :statistic-title="`Triwulan II ${getThisYear} (Responden : ${myData.tw2.responden})`" />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.tw3.indeks} (${myData.tw3.grade})`"
                           color="dark"
                           :statistic-title="`Triwulan III ${getThisYear} (Responden : ${myData.tw3.responden})`" />
                     </b-col>
                     <b-col cols="12" md="6">
                        <statistic-card icon="CheckIcon" :statistic="`${myData.tw4.indeks} (${myData.tw4.grade})`"
                           color="dark"
                           :statistic-title="`Triwulan IV ${getThisYear} (Responden : ${myData.tw4.responden})`" />
                     </b-col>
                  </b-row>
               </b-col>
            </b-row>
         </b-card>
      </b-col>
   </b-row>
</template>

<style scoped>
.oss-color {
   background-color: #8BC6EC;
   background-image: linear-gradient(135deg, #50bf00 0%, #3bc5ff 100%);

}

.oss-logo-color {
   background-color: #ffdee9;
   background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}
</style>
