/* eslint-disable no-param-reassign */
import axios from "axios";
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL;

export default {
   namespaced: true,
   state: {
      oss: {
         izin: 0,
         nib: 0,
         ss: 0,
         syarat_belum: 0,
         menunggu_verifikasi: 0,
      },
      sicantik: {
         izin_year: 0,
         izin_month: 0,
         izin_proses: 0,
      },
      simbg: {
         pbg_year: 0,
         pbg_month: 0,
         slf_year: 0,
         slf_month: 0,
      },
      skm: {
         tw1: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw2: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw3: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw4: {
            indeks: "",
            grade: "",
            responden: 0,
         },
      },
      skmMpp: {
         tw1: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw2: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw3: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw4: {
            indeks: "",
            grade: "",
            responden: 0,
         },
      },
      skmDpmptsp: {
         tw1: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw2: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw3: {
            indeks: "",
            grade: "",
            responden: 0,
         },
         tw4: {
            indeks: "",
            grade: "",
            responden: 0,
         },
      },
      lkpm: {
         tw1_prev: { total: 0, jumlah: 0 },
         tw2_prev: { total: 0, jumlah: 0 },
         tw3_prev: { total: 0, jumlah: 0 },
         tw4_prev: { total: 0, jumlah: 0 },
         tw1: { total: 0, jumlah: 0 },
         tw2: { total: 0, jumlah: 0 },
         tw3: { total: 0, jumlah: 0 },
         tw4: { total: 0, jumlah: 0 },
      },
      pengaduan: {
         selesai: 0,
         proses: 0,
      },
      mppd: {
         terbit: 0,
         batal: 0,
         tolak: 0,
         proses: 0,
      },
   },
   actions: {
      getOss({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/oss`).then(
            (response) => {
               commit("SET_OSS", response.data);

               rootState.isLoading = false;
               return response.data.item;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getSicantik({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/sicantik`).then(
            (response) => {
               commit("SET_SICANTIK", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getSimbg({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/simbg`).then(
            (response) => {
               commit("SET_SIMBG", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getPengaduan({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/pengaduan`).then(
            (response) => {
               commit("SET_PENGADUAN", response.data);
               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getSkm({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/skm`).then(
            (response) => {
               commit("SET_SKM", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getSkm({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/skm`).then(
            (response) => {
               commit("SET_SKM", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getSkmMpp({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/skm/mpp`).then(
            (response) => {
               commit("SET_SKM_MPP", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getSkmDpmptsp({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/skm/dpmptsp`).then(
            (response) => {
               commit("SET_SKM_DPMPTSP", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getLkpm({ commit, rootState }) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}dashboard/lkpm`).then(
            (response) => {
               commit("SET_LKPM", response.data);

               rootState.isLoading = false;
               return response.data;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
   },
   mutations: {
      SET_OSS(state, data) {
         state.oss = data;
      },
      SET_SICANTIK(state, data) {
         state.sicantik = data;
      },
      SET_SIMBG(state, data) {
         state.simbg = data;
      },
      SET_SKM(state, data) {
         state.skm = data;
      },
      SET_SKM_MPP(state, data) {
         state.skmMpp = data;
      },
      SET_SKM_DPMPTSP(state, data) {
         state.skmDpmptsp = data;
      },
      SET_PENGADUAN(state, data) {
         state.pengaduan = data;
      },
      SET_LKPM(state, data) {
         state.lkpm = data;
      },
   },
};
